import {createSlice} from '@reduxjs/toolkit';

const initState = {
    typeWz: 'airport',
    wz: null,
    company: null,
    service: null,
    city: null,
    terminal: null,
    costEstimation: null,
    user: null,
    date: null,
    time: null,
    numFlight: null,
    isLoading: false,
    isActiveBuy: false,
    cardDataRequest: null,
    queue: null
};

export const ticketSlice = createSlice({
    name:'ticket',
    initialState:initState,
    reducers:{
        updateTicketField: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
        }
    }
});

export const {updateTicketField} = ticketSlice.actions;
export default ticketSlice.reducer;