import {createSlice} from '@reduxjs/toolkit';

const initState = 0;

export const stepSlice = createSlice({
    name:'step',
    initialState:initState,
    reducers:{
        // addTask: (state,action)=>{
        //     state.push(action.payload);
        // },
        // deleteTask: (state,action)=>{
        //     const taskF = state.find(task => task.id === action.payload)
        //     if (taskF) {
        //         state.splice(state.indexOf(taskF),1);
        //     }
        // },
        updateStep: (state, action) => {
            return action.payload;
        },
    }
});

export const {updateStep} = stepSlice.actions;
export default stepSlice.reducer;